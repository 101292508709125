<template>
    <div>

        <el-main style=" padding-top: 5px;padding-bottom: initial  ">
            <el-form label-width="80px" @submit.native.prevent>
                <el-row>
                    <el-col :lg="24">
                        <div :class='[this.$store.getters.getRunTimeTest ? "warning" : "tip"]'>
                            <el-row>
                                <el-col :lg="24" style="line-height: 27px;">
                                    <strong style=" font-size: 16px; ">{{this.$store.getters.getRunTimeTest ?'(测试环境,数据定期删除)':''}}当前：发货单一览</strong>
                                </el-col>
                            </el-row>
                        </div>
                    </el-col>
                    <el-col :lg="24">
                        <el-row>
                            <el-col :lg="4" :sm="12" :md="8">
                                <el-date-picker
                                        style=" margin-top: 8px; width: 220px"
                                        v-model="pickerDate"
                                        type="daterange"
                                        size="mini"
                                        :clearable="false"
                                        :picker-options="pickerOptions"
                                        range-separator="至"
                                        start-placeholder="开始日期"
                                        end-placeholder="结束日期"
                                        align="right">
                                </el-date-picker>
                            </el-col>
                            <el-col :lg="8" :sm="12" :md="8">
                                <el-button style=" margin-top: 8px; " id="createBill" type="primary" round
                                           @click="searchAllWarehouse" size="mini">搜索
                                </el-button>
                                <el-button v-if="$_getActionKeyListBoolean(['/shippingAdvice'])"
                                           style=" margin-top: 8px; " type="primary" round
                                           :disabled="$refs.xGrid == null || $refs.xGrid.getCurrentRecord() == null"
                                           @click="openShippingAdvice($refs.xGrid.getCurrentRecord())" size="mini">修改
                                </el-button>
                                <el-button v-if="$_getActionKeyListBoolean(['/shippingAdvice/lodopPrint'])"
                                           style=" margin-top: 8px; " type="primary" round
                                           :disabled="$refs.xGrid == null || $refs.xGrid.getCurrentRecord() == null"
                                           @click="printShippingAdvice($refs.xGrid.getCurrentRecord())" size="mini">查看
                                </el-button>
                                <el-button type="primary" size="mini" @click="$router.back()"
                                           style="margin-bottom: 2px;margin-top: 2px">返回
                                </el-button>
                                <el-button type="success" v-if="$_getActionKeyListBoolean(['/shippingAdvice'])" style=" margin-top: 8px; " round
                                           @click="$router.push('/shippingAdvice')" size="mini">新建
                                </el-button>
                                <el-button v-if="$_getActionKeyListBoolean(['/transferSlip/setIsBillCancel'])"
                                           style=" margin-top: 8px; " type="danger" round
                                           :disabled="$refs.xGrid == null || $refs.xGrid.getCurrentRecord() == null || this.$refs.xGrid.getCurrentRecord().is_cancel_name == '作废'"
                                           @click="setIsBillCancel($refs.xGrid.getCurrentRecord())" size="mini">作废
                                </el-button>
                                <el-checkbox style="padding-left: 7px" v-model="formData.showCancel"
                                             @change="getAllWarehouse()">显示作废
                                </el-checkbox>
                            </el-col>
                            <el-col :lg="6" :sm="12" :md="8">
                                <el-row>
                                    <el-col class="pull-left" :lg="4">
                                        <vxe-toolbar class="pull-left" style=" height: 42px; " custom print export
                                                     ref="xToolbar2">
                                        </vxe-toolbar>
                                    </el-col>

                                </el-row>
                            </el-col>
                        </el-row>
                    </el-col>
                </el-row>
                <el-row>
                    <el-col :lg="24">
                        <vxe-grid
                                border
                                resizable
                                show-overflow
                                keep-source
                                ref="xGrid"
                                align="center"
                                size="mini"
                                :height="(this.getViewHeight() - 130)+''"
                                highlight-current-row
                                :print-config="{}"
                                :data="tableDataMain"
                                :columns="tableColumn"
                                show-footer
                                @cell-dblclick="$_cellClickOpenBill"
                                :footer-method="footerMethod"
                                :row-class-name="tableRowClassName"
                                :cell-class-name="$_cellClassNameSummary"
                                :mouse-config="{selected: false}"
                                @scroll ="$_scrollEvent"
                                :edit-config="{showIcon:true,trigger: 'click', mode: 'cell', showStatus: true}"
                                :keyboard-config="{enterToTab:true,isArrow: true, isDel: false, isEnter: true, isTab: true,isEdit:false}">
                            <template #operate="{row}">
                                <vxe-button v-if="$_getActionKeyListBoolean(['/shippingAdvice'])"
                                            @click="openShippingAdvice(row)">修改
                                </vxe-button>
                                <vxe-button v-if="$_getActionKeyListBoolean(['/shippingAdvice/lodopPrint'])"
                                            @click="printShippingAdvice(row)">查看
                                </vxe-button>
                            </template>

                            <template #pager>
                                <vxe-pager
                                        :layouts="['Sizes', 'PrevJump', 'PrevPage', 'Number', 'NextPage', 'NextJump', 'FullJump', 'Total']"
                                        :page-sizes="[50,500,1000,5000,10000]"
                                        :current-page.sync="tablePage.currentPage"
                                        :page-size.sync="tablePage.pageSize"
                                        :total="tablePage.total"
                                        @page-change="handlePageChange">
                                </vxe-pager>
                            </template>
                        </vxe-grid>
                    </el-col>

                </el-row>

            </el-form>
        </el-main>

    </div>

</template>

<script>
    import XEUtils from 'xe-utils'
    import {filterRender} from "@/utils/gird-filter";


    export default {

        data() {
            return {
                formData: {//搜索筛选
                    name: null,
                    showCancel: false,
               },
                tablePage: {
                    total: 0,
                    currentPage: 1,
                    pageSize: 50,
               },
                pickerOptions: {
                    shortcuts: [{
                        text: '最近一周',
                        onClick(picker) {
                            const end = new Date();
                            const start = new Date();
                            start.setTime(start.getTime() - 3600 * 1000 * 24 * 7);
                            picker.$emit('pick', [start, end]);
                       }
                   }, {
                        text: '最近一个月',
                        onClick(picker) {
                            const end = new Date();
                            const start = new Date();
                            start.setTime(start.getTime() - 3600 * 1000 * 24 * 30);
                            picker.$emit('pick', [start, end]);
                       }
                   }, {
                        text: '最近三个月',
                        onClick(picker) {
                            const end = new Date();
                            const start = new Date();
                            start.setTime(start.getTime() - 3600 * 1000 * 24 * 90);
                            picker.$emit('pick', [start, end]);
                       }
                   }, {
                        text: '最近一年',
                        onClick(picker) {
                            const end = new Date();
                            const start = new Date();
                            start.setTime(start.getTime() - 3600 * 1000 * 24 * 365);
                            picker.$emit('pick', [start, end]);
                       }
                   }, {
                        text: '本月',
                        onClick(picker) {
                            var now = new Date();
                            var year = now.getFullYear();
                            var month = now.getMonth() + 1;
                            if (month < 10) {
                                month = '0' + month;
                           }
                            // 本月第一天
                            var startDate = new Date(year + '-' + month + '-01 00:00:00');
                            picker.$emit('pick', [startDate, new Date(year + '-' + month + '-' + new Date(year, month, 0).getDate() + ' 23:59:00')]);
                       }
                   }, {
                        text: '上个月',
                        onClick(picker) {
                            var now = new Date();
                            var year = now.getFullYear();
                            var month = now.getMonth();
                            if (month == 0) {
                                month = 12;
                                year = year - 1;
                           }
                            if (month < 10) {
                                month = '0' + month;
                           }
                            var myDate = new Date(year, month, 0);
                            // 上个月第一天
                            var startDate = new Date(year + '-' + month + '-01 00:00:00');
                            // 上个月最后一天
                            var endDate = new Date(year + '-' + month + '-' + myDate.getDate() + ' 23:59:00');
                            picker.$emit('pick', [startDate, endDate]);
                       }
                   }]
               },
//visible:false,
                tableColumn: [
                    {title: '序号', type: 'seq', width: 40},
                    {
                        field:'is_cancel_name', width: 60, title: '状态', slots: {
                            header: (params, h) => filterRender(this, params, h, 'autocomplete', {size: 'mini'}, this.filterData, this.sortData, '/admin/allWarehouse/searchHeard', this.pickerDate,null,null,this.searchAllWarehouse)
                       }
                   },
                    /*{field:'operate',title: '操作',fixed:'right',visible:this.$_getActionKeyListBoolean(['/shippingAdvice','/shippingAdvice/lodopPrint']), width: 160, slots: {default: 'operate'}},*/
                    {
                        field:'bill_type', visible: false, width: 90, title: '类型', slots: {
                            header: (params, h) => filterRender(this, params, h, 'autocomplete', {size: 'mini'}, this.filterData, this.sortData, '/admin/allWarehouse/searchHeard', this.pickerDate,null,null,this.searchAllWarehouse)
                       }
                   }, {
                        field:'customer', width: 220, title: '客户', slots: {
                            header: (params, h) => filterRender(this, params, h, 'autocomplete', {size: 'mini'}, this.filterData, this.sortData, '/admin/allWarehouse/searchHeard', this.pickerDate,null,null,this.searchAllWarehouse)
                       }
                   },
                    {
                        field:'print_count', width: 55, title: '打印', slots: {
                            header: (params, h) => filterRender(this, params, h, 'autocomplete', {size: 'mini'}, this.filterData, this.sortData, '/admin/allWarehouse/searchHeard', this.pickerDate,null,null,this.searchAllWarehouse)
                        }
                    },
                    {
                        field:'category', width: 55, title: '类别', slots: {
                            header: (params, h) => filterRender(this, params, h, 'autocomplete', {size: 'mini'}, this.filterData, this.sortData, '/admin/allWarehouse/searchHeard', this.pickerDate,null,null,this.searchAllWarehouse)
                       }
                   },  {
                        field:'full_thick', width: 55, title: '足厚', slots: {
                            header: (params, h) => filterRender(this, params, h, 'autocomplete', {size: 'mini'}, this.filterData, this.sortData, '/admin/allWarehouse/searchHeard', this.pickerDate,null,null,this.searchAllWarehouse)
                       }
                   }, {
                        field:'specification', width: 150, title: '实际规格', slots: {
                            header: (params, h) => filterRender(this, params, h, 'autocomplete', {size: 'mini'}, this.filterData, this.sortData, '/admin/allWarehouse/searchHeard', this.pickerDate,null,null,this.searchAllWarehouse)
                       }
                   },
                    {
                        field:'bill_id', visible: false, width: 120, title: '产品报价-单号', slots: {
                            header: (params, h) => filterRender(this, params, h, 'autocomplete', {size: 'mini'}, this.filterData, this.sortData, '/admin/allWarehouse/searchHeard', this.pickerDate,null,null,this.searchAllWarehouse)
                       }
                   },
                    {
                        field:'bill_id_dd', visible: false, width: 120, title: '销售订单-单号', slots: {
                            header: (params, h) => filterRender(this, params, h, 'autocomplete', {size: 'mini'}, this.filterData, this.sortData, '/admin/allWarehouse/searchHeard', this.pickerDate,null,null,this.searchAllWarehouse)
                       }
                   },
                    {
                        field:'stock_bill_date_dd',
                        visible: false,
                        width: 120,
                        title: '订单日期',
                        formatter: ({cellValue}) => {
                            return this.formatDate(cellValue, 'yyyy-MM-dd')
                       },
                        slots: {
                            header: (params, h) => filterRender(this, params, h, 'autocomplete', {size: 'mini'}, this.filterData, this.sortData, '/admin/allWarehouse/searchHeard', this.pickerDate,null,null,this.searchAllWarehouse)
                       }
                   }, {
                        field:'bill_id_kdtzs', width: 190, title: '开单通知书-单号', slots: {
                            header: (params, h) => filterRender(this, params, h, 'autocomplete', {size: 'mini'}, this.filterData, this.sortData, '/admin/allWarehouse/searchHeard', this.pickerDate,null,null,this.searchAllWarehouse)
                       }
                   },
                    {
                        field:'bill_id_kd', width: 170, title: '销售开单-单号', slots: {
                            header: (params, h) => filterRender(this, params, h, 'autocomplete', {size: 'mini'}, this.filterData, this.sortData, '/admin/allWarehouse/searchHeard', this.pickerDate,null,null,this.searchAllWarehouse)
                       }
                   },
                    {
                        field:'bill_id_fh', width: 170, title: '发货单-单号', slots: {
                            header: (params, h) => filterRender(this, params, h, 'autocomplete', {size: 'mini'}, this.filterData, this.sortData, '/admin/allWarehouse/searchHeard', this.pickerDate,null,null,this.searchAllWarehouse)
                       }
                   },
                    {
                        field:'warehouse_out', visible: false, width: 80, title: '调出仓库', slots: {
                            header: (params, h) => filterRender(this, params, h, 'autocomplete', {size: 'mini'}, this.filterData, this.sortData, '/admin/allWarehouse/searchHeard', this.pickerDate,null,null,this.searchAllWarehouse)
                       }
                   }, {
                        field:'remark_warehouse_bill', width: 80, title: '订单备注', slots: {
                            header: (params, h) => filterRender(this, params, h, 'autocomplete', {size: 'mini'}, this.filterData, this.sortData, '/admin/allWarehouse/searchHeard', this.pickerDate,null,null,this.searchAllWarehouse)
                       }
                   }, {
                        field:'remark_stock', width: 80, title: '产品备注', slots: {
                            header: (params, h) => filterRender(this, params, h, 'autocomplete', {size: 'mini'}, this.filterData, this.sortData, '/admin/allWarehouse/searchHeard', this.pickerDate,null,null,this.searchAllWarehouse)
                       }
                   },
                    {
                        field:'only_bill_date', width: 100, title: '日期', formatter: ({cellValue}) => {
                            return this.formatDate(cellValue, 'yyyy-MM-dd')
                       }, slots: {
                            header: (params, h) => filterRender(this, params, h, 'autocomplete', {size: 'mini'}, this.filterData, this.sortData, '/admin/allWarehouse/searchHeard', this.pickerDate,null,null,this.searchAllWarehouse)
                       }
                   },
                    {
                        field:'tax_tate_warehouse_bill', width: 55, title: '税率', slots: {
                            header: (params, h) => filterRender(this, params, h, 'autocomplete', {size: 'mini'}, this.filterData, this.sortData, '/admin/allWarehouse/searchHeard', this.pickerDate,null,null,this.searchAllWarehouse)
                       }
                   },
                    {
                        field:'single_count', visible: false, width: 80, title: '支数', slots: {
                            header: (params, h) => filterRender(this, params, h, 'autocomplete', {size: 'mini'}, this.filterData, this.sortData, '/admin/allWarehouse/searchHeard', this.pickerDate,null,null,this.searchAllWarehouse)
                       }
                   }, {
                        field:'single_count_str', width: 55, title: '数量', slots: {
                            header: (params, h) => filterRender(this, params, h, 'autocomplete', {size: 'mini'}, this.filterData, this.sortData, '/admin/allWarehouse/searchHeard', this.pickerDate,null,null,this.searchAllWarehouse)
                       }
                   }, {
                        field:'single_weight', width: 90, title: '支重', slots: {
                            header: (params, h) => filterRender(this, params, h, 'autocomplete', {size: 'mini'}, this.filterData, this.sortData, '/admin/allWarehouse/searchHeard', this.pickerDate,null,null,this.searchAllWarehouse)
                       }
                   },
                    {
                        field:'warehouse', width: 65, visible: false, title: '仓库', slots: {
                            header: (params, h) => filterRender(this, params, h, 'autocomplete', {size: 'mini'}, this.filterData, this.sortData, '/admin/allWarehouse/searchHeard', this.pickerDate,null,null,this.searchAllWarehouse)
                       }
                   }, {
                        field:'registrar', width: 70, title: '记录员', slots: {
                            header: (params, h) => filterRender(this, params, h, 'autocomplete', {size: 'mini'}, this.filterData, this.sortData, '/admin/allWarehouse/searchHeard', this.pickerDate,null,null,this.searchAllWarehouse)
                       }
                   }, {
                        field:'tons_of_price', width: 80, title: '吨价', visible: false, slots: {
                            header: (params, h) => filterRender(this, params, h, 'autocomplete', {size: 'mini'}, this.filterData, this.sortData, '/admin/allWarehouse/searchHeard', this.pickerDate,null,null,this.searchAllWarehouse)
                       }
                   }, {
                        field:'amount_of_product', width: 80, title: '产品金额', slots: {
                            header: (params, h) => filterRender(this, params, h, 'autocomplete', {size: 'mini'}, this.filterData, this.sortData, '/admin/allWarehouse/searchHeard', this.pickerDate,null,null,this.searchAllWarehouse)
                       }
                   }, {
                        field:'single_price', width: 80, title: '每支单价', slots: {
                            header: (params, h) => filterRender(this, params, h, 'autocomplete', {size: 'mini'}, this.filterData, this.sortData, '/admin/allWarehouse/searchHeard', this.pickerDate,null,null,this.searchAllWarehouse)
                       }
                   }, {
                        field:'creat_date_warehouse_bill', width: 170, title: '创建日期', slots: {
                            header: (params, h) => filterRender(this, params, h, 'autocomplete', {size: 'mini'}, this.filterData, this.sortData, '/admin/allWarehouse/searchHeard', this.pickerDate,null,null,this.searchAllWarehouse)
                       }
                   }, {
                        field:'order_id', width: 55, title: '排序', slots: {
                            header: (params, h) => filterRender(this, params, h, 'autocomplete', {size: 'mini'}, this.filterData, this.sortData, '/admin/allWarehouse/searchHeard', this.pickerDate,null,null,this.searchAllWarehouse)
                       }
                   }, {
                        field:'b_id', width: 160, visible: false, title: '单据唯一Id', slots: {
                            header: (params, h) => filterRender(this, params, h, 'autocomplete', {size: 'mini'}, this.filterData, this.sortData, '/admin/allWarehouse/searchHeard', this.pickerDate,null,null,this.searchAllWarehouse)
                       }
                   }
                ],
                tableDataMain: [],
                filterData: {
                    "bill_id_type": "FH"
               },
                sortData: {"creat_date_warehouse_bill": "desc"},
                pickerDate: [new Date(new Date().getTime() - 3600 * 1000 * 24 * 7), new Date()],
                activeName: 'first',

           }
       },
        methods: {
            getNumberDefaultZero(obj) {
                //console.log("getNumberDefaultZero : " + obj);
                if (obj == null || typeof (obj) == 'undefined' || isNaN(obj)) {
                    return Number(0)
               } else {
                    return Number(obj)
               }
           },
            sumNum(list, field) {
                let count = Number(0)
                list.forEach(item => {
                    count = this.NumberAdd(count, item[field]);
               })
                return this.getNumberDefaultZero(count)
           },
            footerMethod({columns, data}) {
                const sums = []
                columns.forEach((column, columnIndex) => {
                    if (columnIndex === 0) {
                        sums.push('合计')
                   } else {
                        if (column.property === 'amount_of_product') {
                            sums.push(this.sumNum(data, column.property))
                       } else {
                            sums.push('')
                       }
                   }
               })
                //this.autoComputed();
                // 返回一个二维数组的表尾合计
                return [sums]
           },
            /*fetchSuggestions(){
                //console.log("fetchSuggestions");
           },*/

            searchAllWarehouse() {
                this.tablePage.currentPage = 1;
                this.getAllWarehouse();
           },
            getAllWarehouse() {
                const loading = this.$loading({
                    lock: true,
                    text: '饮茶先啦，数据查询中，请耐心等待!',
                    spinner: 'el-icon-loading',
                    background: 'rgba(0, 0, 0, 0.7)'
                });
                this.getData(loading).then((result)=>{
                    let data = result;
                    //console.log(data)
                    this.tableDataMain = data.page.list;
                    this.tablePage.pageSize = data.page.pageSize;
                    this.tablePage.total = data.page.totalRow;
                    this.tablePage.currentPage = data.page.pageNumber;
                })
            },
            //提取结果集出来，用于无限滚动的时候直接插入
            async getData(loading){
                let data;
                let searchUrl = '/admin/allWarehouse/search'
                await this.$axios({
                    method: 'POST',
                    url: searchUrl,
                    data: {
                        currentPage: this.tablePage.currentPage,
                        pageSize: this.tablePage.pageSize,
                        sortData: this.sortData,
                        filterData: this.filterData,
                        pickerDate: this.pickerDate,
                        formData: this.formData
                    }
                }).then((response) => {          //这里使用了ES6的语法
                    //console.log(response)       //请求成功返回的数据
                    if (loading != null){
                        loading.close();
                    }
                    if (response.status === 200) {
                        return data =  response.data
                        //this.suggestions = []
                        //this.$refs.daterange.click();
                    }
                }).catch((error) => {
                    if (loading != null){
                        loading.close();
                    }
                    console.log(error)
                });
                return data;
            },
            handlePageChange({currentPage, pageSize}) {
                this.tablePage.currentPage = currentPage
                this.tablePage.pageSize = pageSize
                this.getAllWarehouse();
           },
            openShippingAdvice(row) {
                const{href} = this.$router.resolve({path: '/shippingAdvice', query: {bill_id_fh : row.bill_id_fh}});
                window.open(href,'_blank');
                //this.$router.push({path: '/shippingAdvice', query: {bill_id_fh : row.bill_id_fh}})
           },
            printShippingAdvice(row) {
                const{href} = this.$router.resolve({path: '/printShippingAdvice', query: {bill_id_fh : row.bill_id_fh}});
                window.open(href,'_blank');
                //this.$router.push({path: '/printShippingAdvice', query: {bill_id_fh : row.bill_id_fh}})
           },
            formatDate(value, format) {
                return XEUtils.toDateString(value, format)
           },
            tableRowClassName({rowIndex}) {
                if (rowIndex % 2 === 1) {
                    return 'success-row';
               } else {
                    return '';
               }
           },
       },
        created() {
            this.getAllWarehouse();
            this.$nextTick(() => {
                // 手动将表格和工具栏进行关联
                this.$refs.xGrid.connect(this.$refs.xToolbar2)
           })
       }
   };


</script>

<style scoped>
    .header-wrapepr {
        display: flex;
        flex-direction: row;
        justcontent: space-between
   }

    .el-date-editor.el-input, .el-date-editor.el-input__inner {
        width: 130px;
   }

    .el-form-item__content .el-input {
        width: 130px;
   }

    /deep/ .el-input--suffix .el-input__inner {
        padding-right: initial !important;
   }

    /deep/ input[popperclass="myAutocomplete"] {
        padding: 3px !important;
   }

    /deep/ .el-input__suffix {
        right: 0px !important;
   }
</style>
